import Service from "../Service";
import Vue from "vue";
const resource = "/LotProductionCalibrated/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationSupply", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "initialCalibrated", obj, {
            params: { requestID: requestID },
        });
    },

    closeLot(obj, requestID) {
        return Service.post(resource + "closeLot", obj, {
            params: { requestID: requestID },
        });
    },

    anulateParihuela(obj, requestID) {
        return Service.post(resource + "anulateParihuela", obj, {
            params: { requestID: requestID },
        });
    },

    getDataQR(obj, requestID) {},

    GetPalletSupply(TypeShip = 1, RcfID, TrnID, requestID) {
        return Service.post(resource + "GetPalletSupply", "", {
            params: { TypeShip: TypeShip, RcfID: RcfID, TrnID: TrnID, requestID: requestID },
        });
    },

};