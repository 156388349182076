<template>
	<div>
		<v-card style="margin-top: 15px;">
			<v-row style="margin: auto" justify="center">
				<v-col cols="8" lg="3" md="3">
					<s-text
						label="N° Lote Produccion"
						v-model="RcfID"
						autofocus
						ref="rcffocus"
						@keyupEnter="clickSearch()"
						@input="clickSearch()"

					>
					</s-text>
				</v-col>
				<v-col cols="4" lg="3" md="3">
					<v-btn
						style="margin-top: 10px;"
						:color="'success'"
						@click="clickSearch()"
						>Buscar</v-btn
					>
				</v-col>
				<!--ocultamos por obs-->
				<v-col cols="3" style="display: none;"><s-turn-in-process clearable :typeShip="2" :TypeArea="2" v-model="TrnID"></s-turn-in-process>
				</v-col>
			</v-row>
		</v-card>
		<v-card style="margin-top: 5px;">
			<v-row style="margin: auto">
				<v-col cols="12" lg="12" md="12">
					<v-card>
						<v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
							<b>
								Listado Pallet Abastecidos
							</b>
						</v-card-text>
						<v-card-actions> 
                             <v-col cols="12" lg="12" md="12">
                                 <v-data-table
                                    dense
                                    :items="items"
                                    :headers="headers"
                                    :items-per-page="-1"
                                    hide-default-footer
									group-by="RcfID"
									group-desc="RcfID"
									
                                >
									<template v-slot:item.LcsState={item}>
										<v-chip
											x-small
											:color="item.LcsState == 1 ? 'warning' : item.LcsState == 2 ? 'success' : 'error'"
										>
											{{item.LcsState == 1 ? 'En Proceso' : item.LcsState == 2 ? 'Abastecido' : 'Sin Abastecer'}}
										</v-chip>
									</template>
                                </v-data-table>
				            </v-col> 
                        </v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
	import _sLotProdCalibratedSupplyService from "../../../services/FreshProduction/PrfLotProdCalibratedSupplyService";
	import sTurnInProcess from "@/components/FreshProduction/Turn/sTurnInProcess.vue"
	import signalr from "signalr";

	export default {
		components: {sTurnInProcess},
		data: () => ({
			items: [],
			headers: [
				{ text: "Id.", value: "LcsID" },
				{ text: "N° Guia Cosecha", value: "RcfNumberHarvestGuide" },
				{ text: "N° Parihuela.", value: "WpbNumberPallet" },
				{ text: "Cant. Abast.", value: "LcsAmountSupply" },
				{ text: "Variedad", value: "VrtName" },
				{ text: "Cultivo", value: "TypeCultiveName" },
				{ text: "Tipo Cultivo", value: "TypeCropName" },
				{ text: "Calibradora", value: "TypeCalibratorName" },
				{ text: "Tina", value: "TypeTubName" },
				{ text: "Fecha/Hora", value: "SecCreate", width: 150 },
				{ text: "Estado", value: "LcsState" },
			],
			RcfID: 0,
			TrnID: 0
		}),

		created() {
			this.clickSearch()
		},

		watch:{
			
			TrnID(){
				if(this.TrnID == null){
					this.TrnID = 0
				}
				
				this.clickSearch()
			}
		},

		methods: {
			clickSearch() {

				_sLotProdCalibratedSupplyService
					.GetPalletSupply(2, this.RcfID, this.TrnID, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {
							this.items = resp.data;
							this.items.forEach(element => {
								if(element.TypeCalibratorName !== null){
									element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
								}
								
							});
							//this.$refs.rcffocus.focus();
						}
					});
			},
		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.SendMessageSupplyCalibrator = group => {
					this.clickSearch()
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "updateWaitingsupplycalibrator");
				});
			});
		},
	};
</script>
