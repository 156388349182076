import Service from "../Service";
import Vue from "vue";
const resource = "listTurn/"

export default {
    listTurn() {
        alert()
        return Service.post(resource + "listTurn", {}, {
            params: {}
        });
    },
    GetlistTurn() {
        return Vue.prototype.$http.defaults.baseURL + "/listTurn/listTurn"

    },

    listTurnInProcess() {
        return Vue.prototype.$http.defaults.baseURL + "/Turn/listurninprocess"

    }
}